import { CreateFormModal } from 'components/create-form-modal'
import { ActionCompletedModal } from 'components/action-completed-modal'
import { SkillModal } from 'components/skill-modal'
import { PositionModal } from 'components/position-modal'
import { NewTeamModal } from 'components/new-team-modal'
import { InviteModal } from 'components/invite-modal'
import { MovePositionDialog } from 'components/move-position-dialog'
import { MoveTrackDialog } from 'components/move-track-dialog'
import { RequirementModal } from 'components/requirement-modal'
import { SelectRequirementModal } from 'components/select-requirement-modal'
import React from 'react'
import { useDrawerContext } from 'src/design-system'
import { SkillCopyDialog } from 'components/skill-copy-dialog'
import { CheckinCelebratoryModal } from 'components/checkin-celebratory-modal'

const GlobalModals = () => {
  const { closeDrawer } = useDrawerContext()

  React.useEffect(() => {
    const handler = () => {
      closeDrawer()
    }

    document.addEventListener('turbo:click', handler)

    return () => {
      document.removeEventListener('turbo:click', handler)
    }
  })

  return (
    <>
      <ActionCompletedModal />
      <CheckinCelebratoryModal />
      <CreateFormModal />
      <InviteModal />
      <MovePositionDialog />
      <MoveTrackDialog />
      <NewTeamModal />
      <PositionModal />
      <RequirementModal />
      <SelectRequirementModal />
      <SkillCopyDialog />
      <SkillModal />
    </>
  )
}

export default GlobalModals
