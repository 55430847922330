import { CheckCircle, Circle } from '@phosphor-icons/react'
import * as React from 'react'
import cn from 'classnames'
import { CheckinCelebratoryModalVm } from './checkin-celebratory-modal-vm'

type CheckinStepsProps = {
  vm: CheckinCelebratoryModalVm
}

export const CheckinSteps: React.VFC<CheckinStepsProps> = (props) => {
  const { vm } = props

  return (
    <div className="flex flex-col items-start w-full">
      <h6 className="font-bold mb-5 text-gray-900 text-sm">Check-in steps</h6>
      <div className="flex gap-3 mb-4">
        <div>
          <CheckCircle className="h-5 text-theme-40 w-5" weight="fill" />
        </div>
        <p className="line-through mb-0 text-gray-600">
          {vm.checkinStepOneText}
        </p>
      </div>
      <div className="flex gap-3 mb-4">
        <div>
          {vm.checkinStepTwoComplete ? (
            <CheckCircle className="h-5 text-theme-40 w-5" weight="fill" />
          ) : (
            <Circle className="h-5 text-gray-100 w-5" weight="bold" />
          )}
        </div>
        <p
          className={cn(
            'mb-0 text-gray-600',
            vm.checkinStepTwoComplete && 'line-through'
          )}
        >
          {vm.checkinStepTwoText}
        </p>
      </div>
      <div className="flex gap-3">
        <div>
          <Circle className="h-5 text-gray-100 w-5" weight="bold" />
        </div>
        <p className="mb-0 text-gray-600">{vm.checkinStepThreeText}</p>
      </div>
    </div>
  )
}
