import { useModalContext, useModalProps } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStore } from 'store/context'
import { currentUrl, removeUrlParams } from '../../utils/url-helpers'
import * as MegaModal from '../mega-modal'
import { PositionModalContent } from './position-modal-content'
import { POSITION_MODAL_ID, PositionModalProps } from './utils'

export const PositionModal: React.VFC = observer(() => {
  const { positions } = useStore()

  const { positionId, disciplineId, source } =
    useModalProps<PositionModalProps>(POSITION_MODAL_ID)

  const { openModal } = useModalContext()

  const position = positions.byId(positionId, { useDefaultFilter: false })

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)

    const openModalFromUrl = (props: {
      urlParam: string
      type: string
      id: string
      source: string
      removeAdditionalParams?: string[]
    }) => {
      const { urlParam, id, type, source, removeAdditionalParams } = props

      if (!urlParams.has(urlParam) || type != 'Position') return

      openModal<PositionModalProps>(POSITION_MODAL_ID, {
        positionId: id,
        source,
      })
      removeUrlParams(urlParam)
      if (removeAdditionalParams && removeAdditionalParams.length > 0)
        removeUrlParams(...removeAdditionalParams)
    }

    openModalFromUrl({
      urlParam: 'view_modal',
      type: currentUrl().searchParams.get('type') || '',
      id: currentUrl().searchParams.get('id') || '',
      source: currentUrl().searchParams.get('source') || '',
      removeAdditionalParams: ['id', 'type', 'source'],
    })
  }, [openModal])

  return (
    <MegaModal.Root
      data-testid="position-modal"
      defaultOpen={false}
      id={POSITION_MODAL_ID}
      title={position?.name || ''}
    >
      <PositionModalContent
        source={source}
        disciplineId={disciplineId}
        positionId={positionId}
        onChangePosition={(newPositionId, newDisciplineId) => {
          openModal<PositionModalProps>(POSITION_MODAL_ID, {
            positionId: newPositionId,
            disciplineId: newDisciplineId,
          })
        }}
        showCloseButton
      />
    </MegaModal.Root>
  )
})
