import { SkillLevel } from 'store/modules/skill-levels'

export class SelectRequirementModalBodyVm {
  constructor(
    private outcomeState: Record<string, boolean> | null,
    private skillLevels: SkillLevel[]
  ) {}

  calculateAllOutcomesOpen(currentState: boolean, outcomesStates: boolean[]) {
    if (currentState && outcomesStates.every((state) => !state)) return false
    if (!currentState && outcomesStates.every((state) => state)) return true

    return currentState
  }

  updateOutcomesState(open: boolean, skillLevelId?: string) {
    const newOutcomeState = this.outcomeState
      ? { ...this.outcomeState }
      : this.defaultOutcomeState

    if (skillLevelId) {
      newOutcomeState[skillLevelId] = open
    } else {
      Object.keys(newOutcomeState).forEach(
        (key) => (newOutcomeState[key] = open)
      )
    }

    return newOutcomeState
  }

  open(skillLevelId: string) {
    return this.outcomeState?.[skillLevelId] || false
  }

  private get defaultOutcomeState() {
    return this.skillLevels.reduce<Record<string, boolean>>(
      (states, skillLevel) => {
        if (skillLevel.outcomes.length > 0) {
          states[skillLevel.id] = false
        }

        return states
      },
      {}
    )
  }
}
