import pluralize from 'pluralize'
import { store } from 'store/index'
import { ToWords } from 'to-words'

export class TeamLimitBannerVm {
  get newBillingPath() {
    return `/orgs/${store.currentUser?.org?.slug}/billing/new`
  }

  get limitText() {
    const toWords = new ToWords()
    const createTeamsLimit = store.orgLimits.data.get('createTeams')
    if (typeof createTeamsLimit !== 'number') return null
    let text = 'You can only have '
    text += toWords.convert(createTeamsLimit).toLowerCase()
    text += pluralize(' team', createTeamsLimit)
    text += ' on the free plan.'

    return text
  }
}
