import { Button } from 'src/design-system'
import * as React from 'react'
import cn from 'classnames'
import { CheckinCelebratoryModalVm } from './checkin-celebratory-modal-vm'
import { CreateFormModalButton } from 'components/create-form-modal'
import { User } from 'store/modules/users'

type NextActionProps = {
  user: User
  vm: CheckinCelebratoryModalVm
}

export const NextAction: React.VFC<NextActionProps> = (props) => {
  const { user, vm } = props

  return (
    <div
      className={cn(
        'flex mt-10 w-full',
        vm.nextActionText
          ? 'flex-col gap-y-3 items-center md:flex-row md:gap-x-10 md:justify-between'
          : 'justify-center'
      )}
    >
      {vm.nextActionText && (
        <p className="mb-0 text-gray-600 text-left">{vm.nextActionText}</p>
      )}
      {vm.nextAction === 'action' && (
        <CreateFormModalButton
          className="btn btn-brand btn-sm h-8"
          label="Add Action"
          source="checkin_celebratory_modal"
          tabs={['action']}
          user={user}
        />
      )}
      {vm.nextAction === 'checkins' && (
        <Button
          as="a"
          className="btn btn-brand btn-sm h-8"
          data-turbo="false"
          href="/check-ins"
        >
          View Check-ins
        </Button>
      )}
    </div>
  )
}
