import { ArrowRight } from '@phosphor-icons/react'
import { Tooltip } from 'src/design-system'
import * as React from 'react'
import cn from 'classnames'
import pluralize from 'pluralize'
import { CheckinCelebratoryModalVm } from './checkin-celebratory-modal-vm'
import { CheckinStatusLabel } from 'components/checkin-status-label'

type SkillChangesTableProps = {
  vm: CheckinCelebratoryModalVm
}

export const SkillChangesTable: React.VFC<SkillChangesTableProps> = (props) => {
  const { vm } = props

  if (vm.skillChanges.length === 0) return null

  return (
    <div className="flex flex-col items-start mt-10 w-full">
      <h6 className="font-bold mb-1 text-gray-900 text-sm">
        {vm.skillChangesTitle}
      </h6>
      <p className="mb-5 text-gray-600 text-xs">
        <span className="text-gray-900">{vm.totalCheckedInSkills}</span> of{' '}
        <span className="text-gray-900">{vm.totalSkills}</span> checked in.{' '}
        <span className="text-gray-900">{vm.totalUpdatedSkills}</span> updated.
      </p>
      <div className="border border-gray-100 rounded-lg w-full">
        {vm.skillChanges.map((skillChange, index) => (
          <div
            className={cn(
              'flex flex-col sm:flex-row gap-x-4 gap-y-2 justify-between items-start sm:items-center p-4',
              index !== 0 && 'border-0 border-t border-gray-100 border-solid'
            )}
            key={index}
          >
            <div className="truncate w-full">
              <p className="font-bold mb-0 text-xs">
                {pluralize('skill', skillChange.skills.length, true)}
              </p>
              <Tooltip
                content={skillChange.skills.map((skill) => (
                  <div key={skill.id}>{skill.name}</div>
                ))}
              >
                <p className="mb-0 text-gray-600 text-xs truncate">
                  inc.{' '}
                  {skillChange.skills.map((skill) => skill.name).join(', ')}
                </p>
              </Tooltip>
            </div>
            <div className="flex gap-2.5 items-center">
              <CheckinStatusLabel status={skillChange.previous} />
              <ArrowRight className="h-4 text-gray-600 w-4" weight="bold" />
              <CheckinStatusLabel status={skillChange.current} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
