import { X } from '@phosphor-icons/react'
import { GlobalModal, useModalContext, useModalProps } from 'src/design-system'
import React from 'react'
import { OpenFormModalProps, CREATE_FORM_MODAL_ID } from './utils'
import {
  CreateForm,
  CreateFormProps,
  Tabs,
} from 'components/create-form/create-form'
import { trackEvent } from '../../services/event-tracker'
import { store } from 'store/index'
import { sourceFromParams } from '../../services/tracker-helper-service'
import { currentUrl, removeUrlParams } from '../../utils/url-helpers'

export const CreateFormModal: React.VFC = () => {
  const { closeModal } = useModalContext()

  const {
    source,
    tabs,
    user,
    initialTabId,
    initialUserIds,
    initialSkillIds,
    createdFromId,
    createdFromType,
    resourceId,
    resourceType,
    initialContent,
    startOnFromTab,
  } = useModalProps<OpenFormModalProps>(CREATE_FORM_MODAL_ID)

  const onSuccessfulSubmit = () => {
    closeModal()
    // This is a workaround to rerender a page when form is submitted. It is needed by rails components not connected to store.
    document.dispatchEvent(new CustomEvent('createFormModal:submitted'))
  }

  const { openModal } = useModalContext()

  const { currentUser, checkins } = store

  React.useEffect(() => {
    if (!currentUser?.org?.activeSubscription) return

    const urlParams = new URLSearchParams(window.location.search)

    const checkinNotes =
      (currentUser &&
        checkins.lastFinalisedUserCheckin(currentUser.id)?.notes) ??
      ''

    const openModalFromUrl = (props: {
      urlParam: string
      tabs: Tabs
      source: string
      trackingEvent: string
      trackingSource?: string
      initialContent?: string
      initialUserIds?: string[]
      resourceId?: string
      resourceType?: 'Action' | 'Win' | 'Note' | 'FeedbackRequest'
      removeAdditionalParams?: string[]
    }) => {
      const {
        urlParam,
        tabs,
        source,
        trackingEvent,
        trackingSource,
        initialContent,
        initialUserIds,
        resourceId,
        resourceType,
        removeAdditionalParams,
      } = props

      if (!urlParams.has(urlParam)) return
      if (!currentUser) return

      openModal<CreateFormProps>(CREATE_FORM_MODAL_ID, {
        source,
        tabs,
        user: currentUser,
        initialContent,
        initialUserIds,
        resourceId,
        resourceType,
      })
      trackEvent(trackingEvent, { source: trackingSource || source })
      removeUrlParams(urlParam)
      if (removeAdditionalParams && removeAdditionalParams.length > 0)
        removeUrlParams(...removeAdditionalParams)
    }

    openModalFromUrl({
      urlParam: 'new_action',
      tabs: ['action'],
      source: 'action-slash-new',
      trackingEvent: '$track_action_form_opened',
      trackingSource: 'action-slash-new',
      initialContent: checkinNotes,
    })

    openModalFromUrl({
      urlParam: 'new_checkin',
      tabs: ['checkin'],
      source: 'new-checkin',
      trackingEvent: '$track_checkin_form_opened',
      trackingSource: sourceFromParams(),
    })

    openModalFromUrl({
      urlParam: 'new_form',
      tabs: ['win'],
      initialUserIds: [currentUrl().searchParams.get('user_id') || ''],
      source: 'win-dot-new',
      trackingEvent: '$track_win_form_opened',
    })

    openModalFromUrl({
      urlParam: 'open_request_feedback',
      tabs: ['feedback'],
      source: 'feedback-section-open-request-feedback',
      trackingEvent: '$track_request_feedback_modal_opened',
      trackingSource: sourceFromParams(),
    })

    openModalFromUrl({
      urlParam: 'give_feedback',
      tabs: ['feedback'],
      source: 'give-feedback',
      trackingEvent: '$track_give_feedback_modal_opened',
      trackingSource: sourceFromParams(),
      initialUserIds: [currentUrl().searchParams.get('user_id') || ''],
      resourceId: currentUrl().searchParams.get('resource_id') || '',
      resourceType: 'FeedbackRequest',
      removeAdditionalParams: ['user_id', 'resource_id'],
    })
  }, [currentUser, openModal, checkins])

  if (!user) return null

  return (
    <GlobalModal.Root
      id={CREATE_FORM_MODAL_ID}
      title="Add win"
      className="overflow-visible"
      ignoreOutsideClick={true}
    >
      <div className="relative w-full md:w-[600px] lg:w-[800px]">
        <div className="flex flex-row h-5 items-center relative md:absolute md:right-6 md:top-6">
          <GlobalModal.CloseButton className="rounded-sm !outline-none focus:border focus:border-solid focus:!border-theme-60 absolute right-2 top-2 md:static">
            <X weight="bold" className="text-gray-900 w-4 h-4" />
            <span className="sr-only">Close</span>
          </GlobalModal.CloseButton>
        </div>
        <CreateForm
          onSuccess={onSuccessfulSubmit}
          source={source}
          user={user}
          autoFocus={true}
          tabs={tabs}
          initialTabId={initialTabId}
          initialUserIds={initialUserIds}
          initialSkillIds={initialSkillIds}
          createdFromId={createdFromId}
          createdFromType={createdFromType}
          resourceId={resourceId}
          resourceType={resourceType}
          initialContent={initialContent}
          startOnFromTab={startOnFromTab}
        />
      </div>
    </GlobalModal.Root>
  )
}
