import * as React from 'react'
import cn from 'classnames'
import { Banner, Link } from 'src/design-system'
import { TeamLimitBannerVm } from './team-limit-banner-vm'
import { observer } from 'mobx-react-lite'

export type TeamLimitBannerProps = {
  className?: string
}

export const TeamLimitBanner = observer((props: TeamLimitBannerProps) => {
  const { className } = props

  const vm = new TeamLimitBannerVm()

  return (
    <Banner
      variant="highlight"
      className={cn('block text-center rounded', className)}
    >
      {vm.limitText}{' '}
      <Link
        className="p-0 text-pink-700 font-semibold"
        href={vm.newBillingPath}
        data-turbo-frame="content"
        data-turbo-action="advance"
      >
        Upgrade
      </Link>{' '}
      to add more.
    </Banner>
  )
})
