import { Link, Table } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import { Star } from '@phosphor-icons/react'
import * as React from 'react'
import cn from 'classnames'
import { ActionCompletedModalVm } from './action-completed-modal-vm'
import { UserSkill } from 'store/modules/user_skills'
import { CheckinStatusLabel } from 'components/checkin-status-label'
import { CreateFormModalButton } from 'components/create-form-modal'

type ActionCompletedSkillRowProps = {
  index: number
  userSkill: UserSkill
  vm: ActionCompletedModalVm
}

export const ActionCompletedSkillRow = observer(
  (props: ActionCompletedSkillRowProps) => {
    const { index, userSkill, vm } = props

    const skill = userSkill.skill
    const user = userSkill.user

    return (
      <Table.Row className="hover:bg-white" key={skill.id}>
        <Table.Cell className={cn({ 'border-t-0': index === 0 })}>
          <div className="flex items-center gap-2 w-full">
            {userSkill?.focused && (
              <Star
                aria-hidden
                className="h-3.5 text-theme-50 w-3.5"
                weight="fill"
              />
            )}
            <div className="truncate w-full">
              <Link
                className="inline p-0 text-xs truncate"
                href={skill.userSkillUrl}
                onClick={() =>
                  vm.onClickModalAction(vm.eventSource('skill-name'))
                }
                underline={false}
              >
                {skill.name}
              </Link>
            </div>
          </div>
        </Table.Cell>
        <Table.Cell
          className={cn('hidden sm:table-cell md:w-[165px]', {
            'border-t-0': index === 0,
          })}
        >
          {userSkill?.status && (
            <CheckinStatusLabel status={userSkill.status} />
          )}
        </Table.Cell>
        <Table.Cell
          className={cn('w-[80px] md:w-[120px]', {
            'border-t-0': index === 0,
          })}
          right
        >
          <CreateFormModalButton
            className="inline p-0 text-xs bg-white text-theme-50 hover:text-theme-30"
            label="Add Win"
            source="action_complete_modal"
            user={user}
            initialSkillIds={[skill.id]}
            initialUserIds={[user.id]}
            tabs={['win']}
          />
        </Table.Cell>
      </Table.Row>
    )
  }
)
