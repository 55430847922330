import { Button, useModalContext } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { can } from '../../policies'
import { errorToast } from '../../utils/error-toast'
import { LoadingContent } from 'components/loading-content'
import { MultipleFrameworksBanner } from 'components/skill-modal/multiple-frameworks-banner'
import { Requirement } from 'store/modules/requirements'
import { SELECT_REQUIREMENT_MODAL_ID } from 'components/select-requirement-modal/utils'
import { SkillLevelRequirementCard } from 'components/skill-modal/skill-level-requirements-section/skill-level-requirement-card'
import { store } from 'store/index'
import { successToast } from '../../utils/success-toast'
import { SkillVariantDialog } from 'components/skill-variant-dialog'

export type RequirementModalBodyProps = {
  requirement: Requirement
}

export const RequirementModalBody = observer(
  (props: RequirementModalBodyProps) => {
    const { requirement } = props

    const { currentUser } = store

    const [showBanner, setShowBanner] = React.useState(false)

    const [openSkillVariantDialog, setOpenSkillVariantDialog] =
      React.useState(false)

    const position = requirement.position
    const skillVariant = requirement.skillVariant

    if (!skillVariant) return null

    const positionEditable = !!(currentUser && can(currentUser, position).edit)
    const skillEditable = !!(
      currentUser && can(currentUser, skillVariant.skill).edit
    )

    const { openModal, closeModal } = useModalContext()

    React.useEffect(() => {
      store.skillVariants.fetchOne(skillVariant.id, {
        include: ['requirements', 'skill_levels', 'skill_levels.outcomes'],
      })
    }, [skillVariant])

    const skillLevel = requirement.skillLevel

    if (!skillLevel) return null

    const chooseDifferentLevel = () => {
      closeModal()

      openModal(SELECT_REQUIREMENT_MODAL_ID, {
        requirement,
        skill: skillVariant.skill,
        skillVariant,
        position: requirement.position,
      })
    }

    const removeFromPosition = async () => {
      closeModal()

      const response = await requirement.destroy({
        source: 'requirement-modal',
      })

      if (response.success) {
        successToast('Requirement removed from position')
      } else {
        errorToast()
      }
    }

    const frameworkCount = skillVariant.frameworkIds?.length || 0
    const maxLevel = skillVariant.skillLevels?.length || 0
    const source = 'requirement-modal'

    return (
      <LoadingContent
        className="pt-4 md:pt-6"
        loading={store.skillLevels.loading}
      >
        <SkillLevelRequirementCard
          deletableLevel={false}
          deletableOutcome={skillEditable}
          editable={skillEditable}
          frameworkCount={frameworkCount}
          maxLevel={maxLevel}
          outcomes={skillLevel.outcomes}
          setShowBanner={setShowBanner}
          skillLevel={skillLevel}
          skillVariant={skillVariant}
          source={source}
        />
        {positionEditable && (
          <div className="flex pt-4">
            <Button
              variant="outline"
              className="mr-4"
              onClick={chooseDifferentLevel}
            >
              Choose a different level
            </Button>
            <Button
              variant="outline"
              className="text-red-600 border-red-600"
              onClick={removeFromPosition}
            >
              Remove from position
            </Button>
          </div>
        )}
        {showBanner && (
          <MultipleFrameworksBanner
            frameworkCount={frameworkCount}
            skillVariant={skillVariant}
            onCreateVariantClick={() => setOpenSkillVariantDialog(true)}
          />
        )}
        <SkillVariantDialog
          defaultOpen={openSkillVariantDialog}
          onOpenChange={setOpenSkillVariantDialog}
          skillId={skillVariant.skill.id}
          frameworkId={position.framework?.id}
          source={source}
        />
      </LoadingContent>
    )
  }
)
