import { Button, useModalContext } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { LoadingContent } from 'components/loading-content'
import { Position } from 'store/modules/positions'
import { Requirement } from 'store/modules/requirements'
import { SelectRequirementModalBodyVm } from './select-requirement-modal-body-vm'
import { Skill } from 'store/modules/skills'
import { SKILL_MODAL_ID } from 'components/skill-modal/utils'
import { SkillLevel } from 'store/modules/skill-levels'
import { SkillLevelRow } from './skill-level-row'
import { SkillVariant } from 'store/modules/skill-variants'
import { store } from 'store/index'
import { can } from '../../policies'

export type SelectRequirementModalBodyProps = {
  position: Position
  requirement?: Requirement
  skill: Skill
  skillVariant: SkillVariant
  onSuccess?: () => void
}

export const SelectRequirementModalBody = observer(
  (props: SelectRequirementModalBodyProps) => {
    const { position, requirement, skill, skillVariant, onSuccess } = props

    const { closeModal, openModal } = useModalContext()

    const [isViewingAllOutcomes, setIsViewingAllOutcomes] =
      React.useState(false)

    const [outcomesState, setOutcomesState] = React.useState<Record<
      string,
      boolean
    > | null>(null)

    const openSkillModal = () => {
      closeModal()

      openModal(SKILL_MODAL_ID, {
        skillId: skill.id,
        skillVariantId: skillVariant.id,
      })
    }

    React.useEffect(() => {
      store.skillVariants.fetchOne(skillVariant.id, {
        include: ['requirements', 'skill_levels', 'skill_levels.outcomes'],
      })
    }, [skill, skillVariant.id])

    const vm = React.useMemo(() => {
      return new SelectRequirementModalBodyVm(
        outcomesState,
        skillVariant.skillLevels
      )
    }, [outcomesState, skillVariant.skillLevels])

    const onOpenChange = (open: boolean, skillLevelId: string) => {
      const newOutcomesState = vm.updateOutcomesState(open, skillLevelId)
      const allOutcomesOpen = vm.calculateAllOutcomesOpen(
        isViewingAllOutcomes,
        Object.values(newOutcomesState)
      )

      setIsViewingAllOutcomes(allOutcomesOpen)
      setOutcomesState(newOutcomesState)
    }

    return (
      <LoadingContent className="pt-4 md:pt-6" loading={store.skills.loading}>
        <div className="flex items-center justify-end my-2 ">
          <Button
            className="bg-transparent border-0 flex focus:shadow-none items-center p-0 text-sm text-theme-40"
            onClick={() => {
              setIsViewingAllOutcomes(!isViewingAllOutcomes)
              setOutcomesState(vm.updateOutcomesState(!isViewingAllOutcomes))
            }}
          >
            {isViewingAllOutcomes ? 'Hide' : 'View'} all examples
          </Button>
        </div>
        <div className="flex flex-col gap-y-4">
          {skillVariant.skillLevels
            .sort((a, b) => a.level - b.level)
            .map((skillLevel: SkillLevel) => (
              <SkillLevelRow
                key={`skill-level-${skillLevel.id}`}
                onOpenChange={(open) => {
                  onOpenChange(open, skillLevel.id)
                }}
                open={vm.open(skillLevel.id)}
                position={position}
                requirement={requirement}
                skillLevel={skillLevel}
                onSuccess={onSuccess || closeModal}
              />
            ))}
        </div>

        {store.currentUser && can(store.currentUser, skill).edit && (
          <div className="flex mt-4">
            <Button variant="outline" className="mr-4" onClick={openSkillModal}>
              Edit skill
            </Button>
          </div>
        )}
      </LoadingContent>
    )
  }
)
