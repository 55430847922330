import * as React from 'react'
import { CheckinCelebratoryModalVm } from './checkin-celebratory-modal-vm'

type CheckinSummaryProps = {
  vm: CheckinCelebratoryModalVm
}

export const CheckinSummary: React.VFC<CheckinSummaryProps> = (props) => {
  const { vm } = props

  return (
    <div className="flex flex-col gap-4 items-start w-full">
      <div>
        <h6 className="font-bold mb-2 text-gray-900 text-sm">Title</h6>
        <p>{vm.checkinTitle}</p>
      </div>
      <div>
        <h6 className="font-bold mb-2 text-gray-900 text-sm">Summary</h6>
        <p dangerouslySetInnerHTML={{ __html: vm.checkinSummary }} />
      </div>
    </div>
  )
}
