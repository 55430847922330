import {
  Discipline,
  ModifiableDisciplineAttributes,
} from 'store/modules/disciplines'
import { SelectOption } from '../atoms/select/select.types'
import { sortAlphaNumberValues } from '../../utils/sort-helpers'
import { store } from 'store/index'

export class MoveTrackDialogVm {
  constructor(private discipline: Discipline) {}

  get showBanner() {
    return this.users.length > 0
  }

  get teamOptions() {
    return store.teams.editableForCurrentUser
      .reduce<SelectOption[]>((options, team) => {
        if (
          team.framework &&
          team.framework.id !== this.discipline.framework?.id
        ) {
          options.push({ label: team.name, value: team.framework.id })
        }

        return options
      }, [])
      .sort((a, b) => sortAlphaNumberValues(a.label, b.label))
  }

  get users() {
    return this.discipline.positions.flatMap((position) => position.users)
  }

  newErrors(formState: Partial<ModifiableDisciplineAttributes>) {
    return formState['framework'] ? [] : ['framework']
  }

  async updateTrack(formState: Partial<ModifiableDisciplineAttributes>) {
    return await store.disciplines.update(this.discipline.id, formState, {
      include: [
        'framework',
        'framework.frameworks_skills',
        'framework.frameworks_skills.category',
        'framework.positions',
      ],
    })
  }
}
