import { store } from 'store/index'
import { errorToast } from '../../utils/error-toast'

export class NewTeamFormVm {
  constructor(
    private teamName: string,
    private setSubmitting: (value: boolean) => void,
    private setInvalidTeamName: (value: boolean) => void,
    private templateId?: string,
    private editorIds?: string[]
  ) {}

  get showTemplatesField() {
    return store.featureGates.canAccess('templates')
  }

  get templates() {
    return store.teams.templates
  }

  get editorOptions() {
    const editors = store.users.activeInvited

    return editors.map((editor) => ({
      label: editor.fullName,
      value: editor.id,
    }))
  }

  get options() {
    return [
      ...this.templates.map((template) => ({
        label: template.name,
        value: template.id,
      })),
      {
        label: 'Start from scratch',
        value: '',
      },
    ]
  }

  get defaultValue() {
    return this.options.find((option) => option.value === this.templateId)
  }

  async onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    this.setSubmitting(true)
    this.setInvalidTeamName(false)

    const result = await store.teams.create({
      name: this.teamName,
      clonedFrom: this.templateId || undefined,
      editors: this.editorIds || undefined,
    })

    if (result.success) {
      const slug = Object.values(result.data?.teams || {})[0]?.slug

      window.location.href = `/teams/${slug}`
    } else {
      if (
        // TODO: remove in favour of storing errors directly in the store
        result.errors.some(
          (error: { detail: string }) => error.detail === 'Name must be unique.'
        )
      ) {
        this.setInvalidTeamName(true)
      } else {
        errorToast('Something went wrong')
      }
    }

    this.setSubmitting(false)
  }
}
