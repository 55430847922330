import * as React from 'react'
import cn from 'classnames'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { SkillLevelIndicator } from 'components/skill-level-indicator'
import { SkillLevelOutcomesSection } from 'components/skill-level-outcomes-section'
import { SkillLevel } from 'store/modules/skill-levels'
import styles from './styles.module.scss'
import { Position } from 'store/modules/positions'
import { Requirement } from 'store/modules/requirements'
import { store } from 'store/index'
import { FrameworksSkill } from 'store/modules/frameworks-skills'
import { successToast } from '../../utils/success-toast'
import { errorToast } from '../../utils/error-toast'

type SkillLevelRowProps = {
  className?: string
  onOpenChange: (open: boolean) => void
  open: boolean
  position: Position
  requirement?: Requirement
  skillLevel: SkillLevel
  onSuccess?: () => void
}

export const SkillLevelRow: React.VFC<SkillLevelRowProps> = (props) => {
  const {
    className,
    open,
    onOpenChange,
    position,
    requirement,
    skillLevel,
    onSuccess,
  } = props

  const source = 'select-requirement-modal'

  if (!skillLevel.skillVariant) return null

  const frameworksSkill = (
    position.framework.frameworksSkills as FrameworksSkill[]
  ).find((fs) => fs.skillVariant?.id === skillLevel.skillVariant.id)

  const updateRequirement = async (level: number) => {
    try {
      if (requirement) {
        await store.requirements.update(requirement.id, { level }, { source })
        successToast('Position updated')
      } else if (frameworksSkill) {
        await store.requirements.create(
          {
            level,
            position: position.id,
            frameworksSkill: frameworksSkill.id,
          },
          { source }
        )
        successToast('Skill added')
      }
    } catch (e) {
      errorToast('Something went wrong, if this persists please get in touch')
    }

    document.dispatchEvent(new Event('frameworkpage:updated'))
    document.dispatchEvent(new Event('hydrate'))

    // update the position in the store so the new skill is present
    await store.positions.fetchOne(position.id, {
      include: ['requirements', 'skills', 'skills.skill_variants'],
    })

    onSuccess?.()
  }

  return (
    <div
      className={cn(
        'flex flex-col border-solid border-px border-transparent hover:border-gray-100 hover:rounded-md !outline-none',
        className
      )}
      key={skillLevel.id}
    >
      <button
        onClick={() => updateRequirement(skillLevel.level)}
        className="w-full h-full text-left p-3 !outline-none"
      >
        <span className="sr-only">Select level {skillLevel.level}</span>
        <div className="flex gap-2 items-center justify-between">
          <h4 className="font-bold text-gray-900 text-lg truncate">
            Level {skillLevel.level}
          </h4>
          <SkillLevelIndicator
            level={skillLevel.level}
            maxLevel={skillLevel.skillVariant.maxLevel}
          />
        </div>
        {skillLevel.name && (
          <HtmlContent className={cn(styles.htmlContent)}>
            {skillLevel.name}
          </HtmlContent>
        )}
      </button>
      <div className="px-3 pb-3 gap-3 flex flex-col ">
        <SkillLevelOutcomesSection
          onOpenChange={onOpenChange}
          open={open}
          outcomes={skillLevel.outcomes}
        />
      </div>
    </div>
  )
}
