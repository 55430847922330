import { Modal } from 'src/design-system'
import { X } from '@phosphor-icons/react'
import * as React from 'react'
import { OpenFormModalProps } from './utils'
import { CheckinCelebratoryModalVm } from './checkin-celebratory-modal-vm'
import { CheckinSteps } from './checkin-steps'
import { CheckinSummary } from './checkin-summary'
import { NextAction } from './next-action'
import { removeUrlParams } from '../../utils/url-helpers'
import { SkillChangesTable } from './skill-changes-table'
import { store } from 'store/index'
import ShapesPattern from '../../../images/shapes-pattern.svg'

export const CheckinCelebratoryModal: React.VFC = () => {
  const [props, setProps] = React.useState<OpenFormModalProps | null>(null)

  const { currentUser, checkins } = store

  React.useEffect(() => {
    const openCelebrateModal = async () => {
      const urlParams = new URLSearchParams(window.location.search)
      const celebrateCheckinParam = 'celebrate_checkin'
      const id = urlParams.get(celebrateCheckinParam)

      if (!id) return
      if (!currentUser) return

      await checkins.fetchOne(id, {
        include: ['checkin_skills', 'checkin_skills.skill'],
      })

      const fetchedCheckin = checkins.byId(id)
      if (!fetchedCheckin) return

      setProps({
        checkin: fetchedCheckin,
        user: currentUser,
      })

      removeUrlParams(celebrateCheckinParam)
    }

    openCelebrateModal()
  }, [currentUser, checkins])

  const vm = React.useMemo(() => {
    if (!props) return
    return new CheckinCelebratoryModalVm(props.checkin, props.user)
  }, [props])

  const [checkinsLoaded, setCheckinsLoaded] = React.useState(false)

  React.useEffect(() => {
    const fetchPreviousCheckins = async () => {
      if (!vm) return
      await vm.fetchPreviousCheckins()
      setCheckinsLoaded(true)
    }

    if (props?.checkin && vm?.step) fetchPreviousCheckins()
  }, [props, vm])

  if (!props) return null
  if (!vm?.step) return null

  return (
    <Modal.Root
      open
      title="Nicely done!"
      onOpenChange={(open) => {
        if (!open) setProps(null)
      }}
    >
      <div className="border rounded-lg border-gray-200 h-[500px] sm:h-auto relative w-[350px] sm:w-[450px] md:w-[604px]">
        <img alt="" className="w-full" src={ShapesPattern} />
        <Modal.CloseButton asChild>
          <button className="absolute right-6 top-6" title="Close modal">
            <X aria-hidden className="h-4 text-gray-900 w-4" weight="bold" />
          </button>
        </Modal.CloseButton>
        <div className="flex flex-col items-center pb-4 px-4 md:pb-12 md:px-12 ">
          <h2 className="font-bold mb-2 text-2xl text-gray-800">{vm.title}</h2>
          <p className="font-normal mb-10 text-center text-gray-500 text-sm">
            {vm.subtitle}
          </p>
          {vm.notFinalised ? (
            <CheckinSteps vm={vm} />
          ) : (
            <CheckinSummary vm={vm} />
          )}
          {checkinsLoaded && <SkillChangesTable vm={vm} />}
          <NextAction user={props.user} vm={vm} />
        </div>
      </div>
    </Modal.Root>
  )
}
