import { Checkin } from 'store/modules/checkins'
import { CheckinSkill } from 'store/modules/checkin-skills'
import { CheckinStatus } from 'components/checkin-status-label'
import { levelStatus } from '../../utils/checkin-helpers'
import { Skill } from 'store/modules/skills'
import { store } from 'store/index'
import { User } from 'store/modules/users'

export class CheckinCelebratoryModalVm {
  constructor(private checkin: Checkin, private user: User) {}

  get checkinStepOneText() {
    switch (this.step) {
      case 1:
        return 'You decide how you’re progressing in each of your skills'
      case 2:
        return `${this.checkin.author.fname} decides how they’re progressing in each of their skills`
    }
  }

  get checkinStepTwoComplete() {
    return this.step === 2
  }

  get checkinStepTwoText() {
    switch (this.step) {
      case 1:
        return 'Your assessor reviews your answers and adds their own'
      case 2:
        return `You review ${this.checkin.author.fname}'s answers and add your own`
    }
  }

  get checkinStepThreeText() {
    return `Together you agree a final level for each skill and next steps. This is private to you, ${
      this.step === 1 ? 'your assessor' : this.checkin.author.fname
    } and ${this.step === 1 ? 'your' : 'their'} reporting line`
  }

  get checkinSummary() {
    const stripedNotes = this.checkin.notes
      ?.replace(/<[^>]+>/g, '')
      ?.replace(/ /g, '')

    if (this.checkin.notes && stripedNotes !== '') return this.checkin.notes

    return "You didn't write a summary - but you can add one now from the Check-in report."
  }

  get checkinTitle() {
    return this.checkin.titleWithFallback(this.user.id)
  }

  get nextAction() {
    switch (this.step) {
      case 1:
        return 'checkins'
      case 2:
        return 'action'
    }
  }

  get nextActionText() {
    switch (this.step) {
      case 1:
        return 'Review and edit your response.'
      case 2:
        return 'Add an Action to schedule the final Check-in step.'
    }
  }

  get notFinalised() {
    return this.step !== undefined && [1, 2].includes(this.step)
  }

  get skillChanges() {
    return this.checkin.checkinSkills.reduce<
      { current: CheckinStatus; previous: CheckinStatus; skills: Skill[] }[]
    >((changes, checkinSkill) => {
      const current = levelStatus(
        this.currentLevel(checkinSkill),
        checkinSkill.requiredLevel
      )

      const previousCheckinSkill =
        this.previousFinalisedCheckin?.checkinSkills?.find(
          (lastCheckinSkill) =>
            lastCheckinSkill.skill.id === checkinSkill.skill.id
        )

      const previous = levelStatus(
        previousCheckinSkill?.finalLevel,
        previousCheckinSkill?.requiredLevel
      )

      if (current !== previous) {
        const existing = changes.find(
          (change) => change.current === current && change.previous === previous
        )

        if (existing) {
          const index = changes.indexOf(existing)

          if (~index) {
            const skills = existing.skills
            skills.push(checkinSkill.skill)
            changes[index] = { current, previous, skills }
          }
        } else {
          changes.push({ current, previous, skills: [checkinSkill.skill] })
        }
      }

      return changes
    }, [])
  }

  get skillChangesTitle() {
    return this.checkin.positionName
      ? `${this.checkin.positionName} Assessment`
      : 'Assessment'
  }

  get step() {
    if (
      this.user.id === this.checkin.author.id &&
      ['assessor_in_progress', 'pending_assessor'].includes(this.checkin.status)
    ) {
      return 1
    } else if (
      this.user.id === this.checkin.assessor?.id &&
      ['pending_review', 'review_in_progress'].includes(this.checkin.status)
    ) {
      return 2
    } else if (
      this.checkin.userIds.includes(this.user.id) &&
      this.checkin.status === 'finalised'
    ) {
      return 3
    }
  }

  get subtitle() {
    switch (this.step) {
      case 1:
        return 'You’ve completed step 1 of your Check-in.'
      case 2:
        return `You've completed step 2 of ${this.checkin.author.fname}'s Check-in.`
      case 3:
        return "This Check-in is complete. Don't forget to reflect on your growth and set Actions to work on until your next one."
    }
  }

  get title() {
    switch (this.step) {
      case 1:
        return 'Nicely done!'
      case 2:
        return 'Nearly there!'
      case 3:
        return 'Great work!'
    }
  }

  get totalCheckedInSkills() {
    switch (this.step) {
      case 1:
        return this.checkin.checkinSkills.filter(
          (checkinSkill) => !checkinSkill.userSkipped
        ).length
      case 2:
        return this.checkin.checkinSkills.filter(
          (checkinSkill) => !checkinSkill.assessorSkipped
        ).length
      case 3:
        return this.checkin.checkinSkills.filter(
          (checkinSkill) => !checkinSkill.finalSkipped
        ).length
    }
  }

  get totalSkills() {
    return this.checkin.checkinSkills.length
  }

  get totalUpdatedSkills() {
    return this.skillChanges
      .map((skillChange) => skillChange.skills.length)
      .reduce((a, b) => a + b, 0)
  }

  async fetchPreviousCheckins() {
    if (this.notFinalised) {
      await store.checkins.fetchLastFinalisedUserCheckin(this.checkin.author.id)
    } else {
      await store.checkins.fetchAll({
        filter: { author_id: this.checkin.author.id, status: 'finalised' },
        include: [
          'assessor',
          'author',
          'checkin_skills',
          'checkin_skills.skill',
        ],
        page: { size: 2 },
      })
    }
  }

  private get previousFinalisedCheckin() {
    if (this.notFinalised) {
      return store.checkins.lastFinalisedUserCheckin(this.checkin.author.id)
    } else {
      const finalisedSorted =
        store.checkins.finalisedSortedByFinalisedAtDescForAuthor(
          this.checkin.author.id
        )

      return finalisedSorted.find((checkin) => checkin.id !== this.checkin.id)
    }
  }

  private currentLevel(checkinSkill: CheckinSkill) {
    switch (this.step) {
      case 1:
        return checkinSkill.level
      case 2:
        return checkinSkill.assessorLevel
      case 3:
        return checkinSkill.finalLevel
    }
  }
}
