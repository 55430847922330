import { GlobalModal, Table, useModalProps } from 'src/design-system'
import { X } from '@phosphor-icons/react'
import * as React from 'react'
import { ACTION_COMPLETED_MODAL_ID, OpenFormModalProps } from './utils'
import { ActionCompletedSkillRow } from './action-completed-skill-row'
import { ActionCompletedModalVm } from './action-completed-modal-vm'
import ShapesPattern from '../../../images/shapes-pattern.svg'
import { CreateFormModalButton } from 'components/create-form-modal'

export const ActionCompletedModal: React.VFC = () => {
  const { action, source } = useModalProps<OpenFormModalProps>(
    ACTION_COMPLETED_MODAL_ID
  )

  const vm = React.useMemo(() => {
    return new ActionCompletedModalVm(source)
  }, [source])

  if (!action) return null

  return (
    <GlobalModal.Root id={ACTION_COMPLETED_MODAL_ID} title="Action complete!">
      <div className="border rounded-lg border-gray-200 relative w-[300px] sm:w-[450px] md:w-[604px]">
        <img alt="" className="w-full" src={ShapesPattern} />
        <GlobalModal.CloseButton
          asChild
          onClick={() => {
            vm.onClickModalAction(vm.eventSource('close'))
          }}
        >
          <button className="absolute right-6 top-6" title="Close modal">
            <X aria-hidden className="h-4 text-gray-900 w-4" weight="bold" />
          </button>
        </GlobalModal.CloseButton>
        <div className="flex flex-col items-center pb-4 px-4 md:pb-12 md:px-12 ">
          <h2 className="font-bold mb-2 text-2xl text-gray-800">
            Action complete!
          </h2>
          <p className="font-normal mb-0 text-center text-gray-500 text-sm">
            Every action you complete helps you improve your skills.
          </p>
          {action.sortedUserSkills && (
            <Table.Root className="my-8 w-full">
              <Table.Body className="border-collapse bordered">
                {action.sortedUserSkills.map((userSkill, index) => (
                  <ActionCompletedSkillRow
                    index={index}
                    key={userSkill.id}
                    userSkill={userSkill}
                    vm={vm}
                  />
                ))}
              </Table.Body>
            </Table.Root>
          )}
          <CreateFormModalButton
            className="btn btn-brand btn-sm"
            label="Request Feedback"
            source={source}
            tabs={['feedback']}
            user={action.user}
          />
        </div>
      </div>
    </GlobalModal.Root>
  )
}
