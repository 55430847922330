import { store } from 'store/index'

export class NewTeamModalVm {
  get allowCreateTeams() {
    if (store.currentUser?.org?.activeSubscription) return true

    const limit = store.orgLimits.data.get('createTeams')
    if (!limit) return true
    return limit >= store.teams.forOrg.length
  }
}
