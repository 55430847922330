import {
  AvatarButtonGroup,
  Button,
  GlobalModal,
  useModalContext,
  useModalProps,
} from 'src/design-system'
import { Info } from '@phosphor-icons/react'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import pluralize from 'pluralize'
import { avatarGroupProps } from 'app/packs/src/utils/user-helpers'
import {
  Discipline,
  ModifiableDisciplineAttributes,
} from 'store/modules/disciplines'
import { MOVE_TRACK_DIALOG_ID } from './utils'
import { MoveTrackDialogVm } from './move-track-dialog-vm'
import { promiseToast } from '../../utils/promise-toast'
import { Select } from 'components/atoms/select'

export type MoveTrackDialogProps = {
  discipline: Discipline
}

export const MoveTrackDialog: React.VFC = observer(() => {
  const { discipline } =
    useModalProps<MoveTrackDialogProps>(MOVE_TRACK_DIALOG_ID)

  const { closeModal } = useModalContext()

  const [errors, setErrors] = React.useState<string[]>([])

  const [formState, setFormState] = React.useState<
    Partial<ModifiableDisciplineAttributes>
  >({ framework: undefined })

  const moveTrack = async () => {
    const result = await vm.updateTrack(formState)

    if (result.success && discipline.team) {
      window.location.href = discipline.team.baseUrl
    }

    return result
  }

  const onCancel = () => {
    resetErrors()
    closeModal()
  }

  const onSubmit = async () => {
    const newErrors = vm.newErrors(formState)
    setErrors(newErrors)
    if (newErrors.length > 0) return

    promiseToast(
      `move-track-${discipline.id}-dialog`,
      moveTrack(),
      'Track moved',
      undefined,
      'Moving track (this may take a moment)...'
    )
  }

  const resetErrors = () => {
    setErrors([])
  }

  const setFramework = (
    framework: ModifiableDisciplineAttributes['framework']
  ) => {
    setFormState({ framework })
  }

  const vm = React.useMemo(() => {
    return new MoveTrackDialogVm(discipline)
  }, [discipline])

  if (!discipline) return null

  const hasTeamOptions = vm.teamOptions.length > 0

  return (
    <GlobalModal.Root
      className="w-[508px]"
      id={MOVE_TRACK_DIALOG_ID}
      title={discipline.name}
    >
      <div className="p-8">
        <h4 className="font-bold mb-5 text-gray-900 text-lg">
          Move this Track to..
        </h4>
        {hasTeamOptions ? (
          <>
            <h6 className="font-bold mb-2 text-gray-900 text-sm">Team</h6>
            <Select
              invalid={errors.includes('framework')}
              onChange={(frameworkId: string) => setFramework(frameworkId)}
              options={vm.teamOptions}
              triggerClassName="border-gray-200 justify-between max-w-full mb-5 px-4 py-3 w-full"
              triggerContent="Choose a Team"
              value={formState.framework || ''}
            />
            <div className="flex gap-3 items-center mb-4">
              <Info className="h4 text-yellow-700 w-4" weight="bold" />
              <p className="text-gray-900 text-sm">
                All positions, skills and comments will be moved.
              </p>
            </div>
            <div className="flex justify-end gap-2">
              <Button onClick={onCancel} type="button" variant="outline">
                Cancel
              </Button>
              <Button onClick={onSubmit} type="button">
                Move Track
              </Button>
            </div>
          </>
        ) : (
          <div className="flex flex-col gap-4">
            <p className="text-gray-900 text-sm">
              Only admins and editors of multiple teams can move content to
              different teams.
            </p>
            <div className="flex justify-end">
              <Button onClick={onCancel} type="button">
                Ok
              </Button>
            </div>
          </div>
        )}
      </div>
      {vm.showBanner && hasTeamOptions && (
        <div className="bg-yellow-100 flex justify-center gap-2 px-4 py-3">
          <AvatarButtonGroup
            avatars={avatarGroupProps(vm.users, {
              href: true,
              stateTooltip: true,
            })}
            className="pr-1"
            limit={4}
            size="xs"
          />
          <p className="mb-0 text-sm text-yellow-800">
            {pluralize('people', vm.users.length, true)} will be moved to the
            team you choose.
          </p>
        </div>
      )}
    </GlobalModal.Root>
  )
})
