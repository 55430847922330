import { Button, Input, InputGroup, useModalContext } from 'src/design-system'
import * as React from 'react'
import { SelectInput, Option } from 'components/atoms/select-input'
import { NewTeamFormVm } from './new-team-form-vm'
import { openModal } from '../../utils/open-modal'
import { trackEvent } from '../../services/event-tracker'

export type NewTeamFormProps = {
  allowCreateTeams: boolean
  defaultTemplateId?: string
}

export const NewTeamForm = (props: NewTeamFormProps) => {
  const { allowCreateTeams, defaultTemplateId = '' } = props

  const [teamName, setTeamName] = React.useState('')
  const [templateId, setTemplateId] = React.useState(defaultTemplateId)
  const [editorIds, setEditorIds] = React.useState<string[]>([])

  const [submitting, setSubmitting] = React.useState(false)

  const [invalidTeamName, setInvalidTeamName] = React.useState(false)

  const { closeModal } = useModalContext()

  const vm = React.useMemo(() => {
    return new NewTeamFormVm(
      teamName,
      setSubmitting,
      setInvalidTeamName,
      templateId,
      editorIds
    )
  }, [teamName, setSubmitting, setInvalidTeamName, templateId, editorIds])

  const onClickNewTemplate = React.useCallback(() => {
    openModal('/templates/new')
    closeModal()
    trackEvent('$click_new_template_from_team_modal')
  }, [closeModal])

  return (
    <form className="pb-12" onSubmit={(e) => vm.onSubmit(e)}>
      <h2 className="font-bold text-lg mb-2">Create a team</h2>
      <p className="mb-8 text-sm text-gray-600">
        Teams in Progression each contain a group of positions, skills and their
        intersection that make up a career progression framework.
      </p>

      <InputGroup.Root className="mb-8">
        <InputGroup.Label>Team name</InputGroup.Label>
        <Input
          disabled={!allowCreateTeams}
          autoFocus
          required
          value={teamName}
          onChange={(e) => {
            setTeamName(e.target.value)
          }}
          name="team_name"
          isInvalid={invalidTeamName}
        />
        {invalidTeamName && (
          <InputGroup.ErrorMessage>
            Team name is already taken
          </InputGroup.ErrorMessage>
        )}
      </InputGroup.Root>
      {vm.showTemplatesField && (
        <InputGroup.Root className="mb-8">
          <InputGroup.Label>Select template</InputGroup.Label>
          <SelectInput
            disabled={!allowCreateTeams}
            multi={false}
            name="template"
            options={vm.options}
            defaultValue={vm.defaultValue}
            onChange={(value) => {
              setTemplateId((value as Option).value.toString())
            }}
            placeholder="No template"
          />
          <button
            type="button"
            onClick={onClickNewTemplate}
            className="text-theme-50 mt-2"
          >
            Create new template
          </button>
        </InputGroup.Root>
      )}
      <InputGroup.Root className="mb-8">
        <InputGroup.Label>Assign editors</InputGroup.Label>
        <SelectInput
          disabled={!allowCreateTeams}
          multi
          name="editor_ids"
          options={vm.editorOptions}
          onChange={(value) => {
            setEditorIds((value as Option[]).map((o) => o.value.toString()))
          }}
          placeholder="No editors"
        />
        <p className="mt-2 text-gray-600">
          <span className="text-gray-900">Editors</span> can add content and
          team members to this team.
        </p>
      </InputGroup.Root>
      <Button
        disabled={submitting || !allowCreateTeams}
        type="submit"
        className="ml-auto"
        size="lg"
      >
        Create team
      </Button>
    </form>
  )
}
