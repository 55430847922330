import { Info, X } from '@phosphor-icons/react'
import {
  Banner,
  Button,
  GlobalModal,
  Textarea,
  useModalContext,
  useModalProps,
} from 'src/design-system'
import React from 'react'
import { emptyInvitee, INVITE_MODAL_ID } from './utils'
import { InviteesList } from './invitees-list'
import { InviteModalVm } from './invite-modal-vm'
import { SeatsRemainingTag } from 'components/seats-remaining-tag'
import { InviteBanner } from 'components/invite-banner'

export type InviteModalProps = {
  /**
   * The intent of the invite. Used to determine specic copy and behavior. Also used
   * in reporting to determine where a user invite was initiated from.
   */
  intent: string
  positionId?: string
}

export type Invitee = {
  id: number
  email: string
  fname: string
  lname: string
  positionId: string
  admin: boolean
  error: string | null
}

const InviteModalContent: React.VFC<InviteModalProps> = ({
  intent,
  positionId,
}) => {
  const { closeModal } = useModalContext()

  const createEmptyInvitee = React.useCallback<() => Invitee[]>(() => {
    return [
      {
        ...emptyInvitee,
        positionId: positionId || '',
      },
    ]
  }, [positionId])

  const [invitees, setInvitees] = React.useState<Invitee[]>(createEmptyInvitee)
  const [inviteMessage, setInviteMessage] = React.useState(
    `Check out what I've been working on in Progression 👀`
  )

  const vm = new InviteModalVm(invitees, setInvitees, intent, inviteMessage)
  const validInvitees = invitees.filter((invitee) => invitee.email.length > 0)

  return (
    <>
      <div className="flex w-full justify-between px-4 py-3 border-t-0 border-r-0 border-l-0 border-b border-gray-200 border-solid">
        <div className="flex justify-start items-center">
          <h2 className="flex text-sm mr-3">Invite new teammates</h2>
          <SeatsRemainingTag inviteesLength={invitees.length} />
        </div>
        <GlobalModal.CloseButton className="rounded-sm !outline-none focus:border focus:border-solid focus:!border-theme-60 absolute right-2 top-2 md:static">
          <X weight="bold" className="text-gray-900 w-4 h-4" />
          <span className="sr-only">Close</span>
        </GlobalModal.CloseButton>
      </div>
      <div className="p-12">
        <div>
          <form
            className="mb-2"
            onSubmit={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              const submitted = await vm.onSubmit()

              if (submitted) {
                closeModal()
              }
            }}
          >
            <InviteesList invitees={invitees} setInvitees={setInvitees} />

            <InviteBanner
              inviteesLength={validInvitees.length}
              className="mb-4"
              source="multi-user-invite"
            />

            <p className="text-base mb-2">
              <strong>Invite message</strong>
            </p>

            <Textarea
              defaultValue={inviteMessage}
              onChange={(e) => setInviteMessage(e.target.value)}
            ></Textarea>

            <p className="text-sm text-gray-600 mb-5">
              This message will be included in the invite email.
            </p>

            {vm.inviteesHaveErrors && (
              <Banner variant="danger" className="mb-4 rounded">
                <div className="flex flex-row items-center gap-x-2 w-full">
                  <Info size={18} className="mr-1 w-8" />
                  {
                    "The email addresses above can't be used. Please check them and try again."
                  }
                </div>
              </Banner>
            )}

            {vm.invitingAnAdmin && (
              <Banner variant="warning" className="mb-4 rounded">
                <div className="flex flex-row items-center gap-x-2 w-full">
                  <Info size={18} className="mr-1 w-8" />
                  Admin users can edit and delete all framework content, invite
                  and archive users, and access other potentially sensitive user
                  and org data including your billing plan.{' '}
                  <a
                    href="https://help.progressionapp.com/en/articles/5615646-faq-s-roles-and-permissions-on-progression"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-theme-60 underline"
                  >
                    Read more
                  </a>
                </div>
              </Banner>
            )}

            <div className="flex justify-end items-center">
              <Button size="default" type="submit">
                Send invites
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export const InviteModal: React.VFC = () => {
  const { intent, positionId } =
    useModalProps<InviteModalProps>(INVITE_MODAL_ID)

  return (
    <GlobalModal.Root
      id={INVITE_MODAL_ID}
      title="Invite team members"
      className="w-full sm:w-[800px] md:w-[800px] lg:w-[1000px]"
    >
      {intent && <InviteModalContent intent={intent} positionId={positionId} />}
    </GlobalModal.Root>
  )
}
