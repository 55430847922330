import { observer } from 'mobx-react-lite'
import { useModalContext, useModalProps } from 'src/design-system'
import React from 'react'
import { currentUrl, removeUrlParams } from '../../utils/url-helpers'
import { SKILL_MODAL_ID, SkillModalProps } from './utils'
import { SkillModalContent } from './skill-modal-content'
import { useStore } from 'store/context'
import * as MegaModal from '../mega-modal'

export const SkillModal: React.VFC = observer(() => {
  const {
    frameworkId,
    showControls,
    showMakeACopy,
    showRemoveFromTeam,
    skillId,
    skillVariantId,
    source,
  } = useModalProps<SkillModalProps>(SKILL_MODAL_ID)

  const { skills } = useStore()

  const { openModal } = useModalContext()

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)

    const openModalFromUrl = (props: {
      id: string
      removeAdditionalParams?: string[]
      skillId?: string
      source: string
      type: string
      urlParam: string
    }) => {
      const { id, removeAdditionalParams, skillId, source, type, urlParam } =
        props

      if (!skillId || type !== 'SkillVariant' || !urlParams.has(urlParam)) {
        return
      }

      openModal<SkillModalProps>(SKILL_MODAL_ID, {
        skillId,
        skillVariantId: id,
        source,
      })

      removeUrlParams(urlParam)

      if (removeAdditionalParams && removeAdditionalParams.length > 0) {
        removeUrlParams(...removeAdditionalParams)
      }
    }

    openModalFromUrl({
      id: currentUrl().searchParams.get('id') || '',
      removeAdditionalParams: ['id', 'skill_id', 'source', 'type'],
      skillId: currentUrl().searchParams.get('skill_id') || '',
      source: currentUrl().searchParams.get('source') || '',
      type: currentUrl().searchParams.get('type') || '',
      urlParam: 'view_modal',
    })
  }, [openModal])

  const skill = skills.byId(skillId)

  return (
    <MegaModal.Root
      data-testid="skill-modal"
      defaultOpen={false}
      id={SKILL_MODAL_ID}
      title={skill?.name || ''}
    >
      <SkillModalContent
        {...{
          frameworkId,
          showControls,
          showMakeACopy,
          showRemoveFromTeam,
          skillId,
          skillVariantId,
          source,
        }}
        showCloseButton
      />
    </MegaModal.Root>
  )
})
