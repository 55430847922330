import * as React from 'react'
import { GlobalModal, useModalProps } from 'src/design-system'
import { SELECT_REQUIREMENT_MODAL_ID } from './utils'
import { X } from '@phosphor-icons/react'
import { SelectRequirementModalBody } from './select-requirement-modal-body'
import { Requirement } from 'store/modules/requirements'
import { Skill } from 'store/modules/skills'
import { Position } from 'store/modules/positions'
import { SkillVariant } from 'store/modules/skill-variants'

export type SelectRequirementModalProps = {
  position: Position
  requirement?: Requirement
  skill: Skill
  skillVariant: SkillVariant
  onSuccess?: () => void
}

export const SelectRequirementModal: React.VFC = () => {
  const { skill, skillVariant, position, requirement, onSuccess } =
    useModalProps<SelectRequirementModalProps>(SELECT_REQUIREMENT_MODAL_ID)

  return (
    <GlobalModal.Root
      className="w-[810px]"
      id={SELECT_REQUIREMENT_MODAL_ID}
      title="Select Requirement"
    >
      <div className="border rounded-lg border-gray-200 relative">
        <h2 className="font-bold p-4 text-sm text-gray-800 border-bottom ">
          {skill?.name} as {position?.name}
        </h2>

        <GlobalModal.CloseButton asChild>
          <button className="absolute right-4 top-4" title="Close modal">
            <X aria-hidden className="h-4 text-gray-900 w-4" weight="bold" />
          </button>
        </GlobalModal.CloseButton>
        <div className="flex flex-col p-4 pt-0 md:pt-0 md:p-6">
          {skill && (
            <SelectRequirementModalBody
              requirement={requirement}
              skill={skill}
              skillVariant={skillVariant}
              position={position}
              onSuccess={onSuccess}
            />
          )}
        </div>
      </div>
    </GlobalModal.Root>
  )
}
