import { GlobalModal, useModalProps } from 'src/design-system'
import { X } from '@phosphor-icons/react'
import * as React from 'react'
import { Requirement } from 'store/modules/requirements'
import { REQUIREMENT_MODAL_ID } from './utils'
import { RequirementModalBody } from './requirement-modal-body'
import { SkillEditableByLabel } from 'components/skill-editable-by-label'

export type RequirementModalProps = {
  requirement: Requirement
}

export const RequirementModal: React.VFC = () => {
  const { requirement } =
    useModalProps<RequirementModalProps>(REQUIREMENT_MODAL_ID)

  const skill = requirement?.skill
  if (!skill) return null

  return (
    <GlobalModal.Root
      className="w-[810px]"
      id={REQUIREMENT_MODAL_ID}
      title={`${skill.name} level ${requirement.level}`}
    >
      <div className="border rounded-lg border-gray-200 relative">
        <h2 className="flex justify-between items-center font-bold text-sm py-4 pl-4 pr-12 text-gray-800 border-bottom ">
          {skill.name} as {requirement.position?.name}
          <SkillEditableByLabel id={skill.id} />
        </h2>

        <GlobalModal.CloseButton asChild>
          <button className="absolute right-4 top-4" title="Close modal">
            <X aria-hidden className="h-4 text-gray-900 w-4" weight="bold" />
          </button>
        </GlobalModal.CloseButton>
        <div className="flex flex-col p-4 pt-0 md:pt-0 md:p-6">
          <RequirementModalBody requirement={requirement} />
        </div>
      </div>
    </GlobalModal.Root>
  )
}
