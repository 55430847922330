import pluralize from 'pluralize'
import { store } from 'store/index'
import { z } from 'zod'
import { trackEvent } from '../../services/event-tracker'
import { successToast } from '../../utils/success-toast'
import { Invitee } from './invite-modal'

export class InviteModalVm {
  constructor(
    private invitees: Invitee[],
    private setInvitees: (invitees: Invitee[]) => void,
    private intent: string,
    private inviteMessage: string
  ) {}

  async onSubmit() {
    const valid = this.validate()

    if (!valid) {
      return false
    }

    const toInvite = this.invitees.filter((invitee) => !!invitee.email)

    if (toInvite.length === 0) {
      return true
    }

    const result: Invitee[] = await Promise.all(
      toInvite.flatMap(async (invitee) => {
        const res = await store.users.create({
          fname: this.firstNameValue(invitee),
          lname: this.lastNameValue(invitee),
          email: invitee.email,
          manager: store.currentUser?.id,
          editor: '0',
          intent: this.intent,
          position: invitee.positionId,
          org_role: invitee.admin ? '1' : '0',
          invite_message: this.inviteMessage,
        })

        return {
          ...invitee,
          error: res.success
            ? null
            : res.errors[0].title ?? 'Something went wrong inviting this user',
        }
      })
    )

    const errors = result.filter((r) => !!r.error)
    const successCount = toInvite.length - errors.length

    if (successCount > 0) {
      trackEvent('$track_multi_invited', {
        userId: store.currentUser?.id,
        action: 'invited',
        permission: 'view',
        count: successCount,
      })

      // refresh org to update seats remaining
      store.currentUser?.reload(['org'])

      successToast(
        `${successCount} ${pluralize(
          'teammate',
          successCount
        )} invited successfully`
      )
    }

    if (errors.length > 0) {
      this.setInvitees(errors)

      return false
    }

    return true
  }

  private validate(): boolean {
    const withErrors = this.invitees.map((invitee) => {
      const error = this.checkEmail(invitee)
      return {
        ...invitee,
        error: error,
      }
    })

    this.setInvitees(withErrors)

    return withErrors.every((invitee) => invitee.error == null)
  }

  checkEmail(invitee: Invitee) {
    const isEmpty =
      invitee.email == '' && invitee.positionId == '' && !invitee.admin

    // ignore rows that have just been added and not edited
    if (isEmpty) return null

    if (invitee.email == '') return 'Please enter an email address'

    if (!this.isEmailValid(invitee.email))
      return 'Please use a valid email address'
    if (this.emailTaken(invitee.email))
      return 'Someone with this email has already been invited'
    if (this.isEmailAlreadyAdded(invitee.id, invitee.email))
      return 'This email has already been added'

    return null
  }

  emailTaken(emailBeingAdded: string): boolean {
    return store.users.all.some((user) => user.email == emailBeingAdded)
  }

  isEmailValid(emailBeingAdded: string) {
    const FormData = z.object({
      email: z.string().email(),
    })

    return FormData.safeParse({ email: emailBeingAdded }).success
  }

  isEmailAlreadyAdded(id: number, emailBeingAdded: string): boolean {
    return this.invitees.some(
      (invitee) => invitee.id !== id && invitee.email == emailBeingAdded
    )
  }

  firstNameValue(invitee: Invitee): string {
    if (invitee.fname) return invitee.fname

    return invitee.email.split('@')[0].split('.')[0]
  }

  lastNameValue(invitee: Invitee): string {
    if (invitee.lname) return invitee.lname

    return invitee.email.split('@')[0].split('.')[1]
  }

  get inviteesHaveErrors(): boolean {
    return this.invitees.some((invitee) => invitee.error != null)
  }

  get invitingAnAdmin(): boolean {
    return this.invitees.some((invitee) => invitee.admin)
  }
}
