import { GlobalModal, useModalProps } from 'src/design-system'
import { X } from '@phosphor-icons/react'
import * as React from 'react'
import { NEW_TEAM_MODAL_ID } from './utils'
import teamModalIllustration from 'app/packs/images/team-modal-illustration.svg'
import { observer } from 'mobx-react-lite'
import { NewTeamForm } from './new-team-form'
import { TeamLimitBanner } from './team-limit-banner'
import { NewTeamModalVm } from './new-team-modal-vm'

export const NewTeamModal = observer(() => {
  const { templateId } = useModalProps<{
    templateId?: string
  }>(NEW_TEAM_MODAL_ID)

  const vm = new NewTeamModalVm()

  return (
    <GlobalModal.Root
      id={NEW_TEAM_MODAL_ID}
      title="Create team"
      className="!max-w-[808px] h-auto"
    >
      <div className="flex flex-row justify-between items-stretch w-full">
        <div className="flex-shrink-0 bg-gray-50 flex items-center">
          <img className="w-full" src={teamModalIllustration} alt="" />
        </div>
        <div className="border-0 border-l border-solid border-gray-100">
          <GlobalModal.CloseButton className="mr-4 mt-4 p-2 ml-auto flex items-center rounded-sm !outline-none border-px border-solid border-transparent focus:!border-theme-60">
            <X weight="bold" className="text-gray-900 w-4 h-4" />
            <span className="sr-only">Close</span>
          </GlobalModal.CloseButton>
          <div className="px-12">
            {!vm.allowCreateTeams && <TeamLimitBanner className="my-4" />}
            <NewTeamForm
              allowCreateTeams={vm.allowCreateTeams}
              defaultTemplateId={templateId}
            />
          </div>
        </div>
      </div>
    </GlobalModal.Root>
  )
})
