import { trackEvent } from '../../services/event-tracker'

export class ActionCompletedModalVm {
  constructor(private source: string) {}

  eventSource(subsource: string) {
    return `${this.source}-${subsource}`
  }

  onClickFormModalButton(source: string) {
    trackEvent('$track_action_form_opened', { source })
  }

  onClickModalAction(source: string) {
    trackEvent('$track_after_action_complete_click', { source })
  }
}
